<template>
    <transition name="fade">
        <div class="app-container" v-if="ready">
            <portal-target name="sc-modal" transition="fade-in-transition"></portal-target>
            <sc-nav></sc-nav>
            <router-view></router-view>
        </div>
    </transition>
</template>

<script>
export default {
  name: 'app',

  data() {
    return {
      ready: false
    }
  },

  mounted() {
    this.$store.dispatch('user/getUser', {talker: this.$talker})
      .then(() => {
        this.ready = true;
      })
  },
}
</script>

<style scoped lang="scss">
.app-container {
    padding-left: 250px;
}

.fade-enter-active, .fade-leave-active {
    transition: opacity $easing $transition-duration;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}
</style>