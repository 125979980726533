<template>
    <div class="radio-buttons">
        <div class="radio-button" v-for="option in options" @click="select(option)">
            <div class="checkbox">
                <awesome-icon class="icon" icon="check" v-if="isSelected(option)"/>
            </div>
            <div class="label">
                {{ option.label }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "nr-radio",

  props: {
    options: {},
    value: {},
    multi: {
      type: Boolean,
    }
  },

  mounted() {
    this.$nextTick(() => {
      if (this.multi === true) {
        if (!Array.isArray(this.value)) {
          throw new Error("NR-RADIO requires an (:ARRAY) VALUE when set to MULTI mode");
        }
      } else {
        if (typeof this.value !== 'string') {
          throw new Error("NR-RADIO requires an (:STRING) VALUE");
        }
      }
    })

  },

  methods: {
    select(option) {
      if (this.multi === true) {
        let selected = [...this.value];
        let index = selected.indexOf(option.value);
        if (index === -1) {
          selected.push(option.value);
        } else {
          selected.splice(index, 1);
        }
        this.$emit('update:value', selected);
      } else {
        this.$emit('update:value', option.value);
      }
    },

    isSelected(option) {
      if (this.multi === true) {
        return !!this.value.includes(option.value)
      } else {
        return this.value === option.value;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.radio-buttons {
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(200px, 1fr) );
    grid-gap: 0.5em 32px;

    .radio-button {
        padding: 0.2em;
        display: flex;

        &:hover {
            cursor: pointer;
        }

        .checkbox {
            flex-shrink: 0;
            width: 1.1em;
            height: 1.1em;
            border: 2px solid $color-primary;
            border-radius: 0.2em;
            text-align: center;
            line-height: 1.2em;
            font-size: 0.8em;
            padding: 0.2em;
            margin-right: 0.4em;
        }

        .label {
            line-height: 1.1em;
        }
    }
}
</style>