import index from './index.vue'

const routes = {
  path: 'account',
  component: index,
  children: [
    {
      path: '',
      component: () => import('./all'),
    },
    {
      path: 'change-password',
      component: () => import('./pw-reset'),
    }
  ]
};

export default routes;
