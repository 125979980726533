import moment from "moment-timezone";

export default function toDatetime(date) {
  if (date) {
    let m = moment(date);
    let adjusted = m.format('D-MM-Y, HH:mm');
    return adjusted
  } else {
    return;
  }
}
