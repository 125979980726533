import index from './index.vue'

const routes = {
  path: 'ois-messages',
  component: index,
  children: [
    {
      path: 'index',
      component: () => import('./all.vue'),
    },
    {
      path: 'new',
      component: () => import('./new.vue'),
    },
    {
      path: ':message_id/edit',
      component: () => import('./edit.vue'),
    },
  ]
};

export default routes;
