<template>
    <div>
        <children-route :passed="resource"></children-route>
    </div>
</template>

<script>
export default {
  name: 'index',

  data() {
    return {
      resource: {
        route: 'companies',
        route_param: 'company_id',
        api: 'companies',
        name: 'Company',
        plural: 'Companies',
      }
    }
  }
}
</script>