<template>
    <sc-table :table="table">
        <template #header>
            <div class="table-header">
                Media Usage
            </div>
            <div class="sub">
                <div class="filters">
                </div>

                <div class="actions">
                    <sc-button class="action" @click="report('download')"
                    :disabled="loading" :running="loading">
                        <awesome-icon icon="download" class="icon"/>
                        Download Report
                    </sc-button>
                </div>
            </div>
        </template>
    </sc-table>
</template>

<script>
import exporter from "@/views/app/reports/functions/exporter";

export default {
  name: "media-in-use",
  data() {
    return {
      loading: false,
      table: this.$stable.createTable({
        name: "Media usage",
        url: 'reports/media-usage',
        amount: 100,
        sortDir: 'desc',
        columns: {
          name: {sort: false, fill: true},
          playlist_media_usage_count: {
            name: "Playlist Usage"
          },
          template_media_usage_count: {
            name: "Template Usage"
          },
          total_media_usage_count: {
            name: "Total Usage"
          }
        },
        success: () => {
          this.loading = false;
        }
      })
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.loading = true;
      this.table.fetch();
    })
  },

  methods: {
    report(type) {
      this.loading = true;
      if (type === 'generate') {
        this.table.fetch()
        .catch((err) => {
          this.loading = false;
        })
      } else if (type === 'download') {
        this.downloadReport()
      }
    },

    downloadReport() {
      let query = 'reports/export/media-usage';
      this.$talker.api(query, {
        responseType: 'blob'
      })
        .then((res) => {
          exporter(res.data, "mediausage");
          this.loading = false;
        })
        .catch((err) => {
          this.$notify.error("Something went wrong");
          this.loading = false;
        })
    },
  }
}
</script>

<style scoped lang="scss">

.sub {
    display: flex;
    padding: 1em 1.5em;

    .filters {
        display: flex;
        align-items: center;
        flex: 1;

        .description {
            margin-right: 1em;
        }

        .filter {
            display: flex;
            text-align: center;
            box-sizing: border-box;

            label {
                display: flex;
                font-weight: bold;
                cursor: pointer;
                margin: 0 .5em;

                input {
                    min-width: initial;
                    widtH: 2em;
                }
            }
        }
    }

    .actions {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;

        .action {
            margin: .25em 0;
        }
    }

}

</style>