<template>
    <sc-modal title="Select User" @close="$emit('close')">

        <div class="bar">
            <sc-search @searched="search" field="q" style="flex:1;margin-right: 1em;"></sc-search>
            <div class="clear" @click="clear">
                <awesome-icon icon="times"/>
                Clear filter
            </div>
        </div>

        <div class="users" v-if="table.data">
            <div v-for="u in table.data.data" class="user" @click="selectUser(u)">{{ u.attributes.email }}</div>
        </div>

        <sc-paginate :table="table"/>

    </sc-modal>
</template>

<script>
import ScSearch from "@/components/sc-search";

export default {
  name: "select-user",
  components: {ScSearch},
  data() {
    return {
      q: undefined,
      table: this.$stable.createTable({
        name: 'users',
        url: 'users',
        fetchArguments: () => {
          if (this.q) {
            return `&q=${this.q}`
          }
        },
        columns: {
          email: {target: 'attributes.email'}
        }
      })
    }
  },
  mounted() {
    this.table.fetch();
  },
  methods: {
    selectUser(user) {
      this.$emit('select', user);
      this.$emit('close');
    },
    clear() {
      this.$emit('clear');
      this.$emit('close');
    },
    search(value) {
      this.q = value;
      this.table.fetch();
    }
  }
}
</script>

<style scoped lang="scss">

.user {
    border: 1px solid #e2e2e2;
    border-bottom: 0;
    padding: .5em .75em;
    cursor: pointer;

    &:last-child {
        border-bottom: 1px solid #e2e2e2;
    }

    &:hover {
        background: #f6f6f6;
    }
}

.bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;

    .clear {
        padding: .5em .75em;
        text-align: center;
        cursor: pointer;

        &:hover {
            background: #f6f6f6;
        }
    }
}



</style>