export default {
  install(Vue, params) {

    // Import all the functions under the $functions namespace inside Vue
    const filters = require.context('./', false, /\.js$/i);

    filters.keys().map(key => {
      if (key !== './index.js') {
        Vue.filter(key.split('/').pop().split('.')[0], require('./' + key.split('/').pop().split('.')[0]).default)
      }
    });
  }
};