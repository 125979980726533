<template>
    <div>
        <children-route :passed="resource"></children-route>
    </div>
</template>

<script>
export default {
  name: 'index',

  data() {
    return {
      resource: {
        route: 'player-metadata',
        route_param: 'player_metadata_id',
        api: 'player-metadata',
        name: 'Player Metadata',
        plural: 'Player Metadata',
      }
    }
  }
}
</script>