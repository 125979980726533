<template>
    <div>
        <children-route :passed="resource"></children-route>
    </div>
</template>

<script>
export default {
  name: 'index',

  data() {
    return {
      resource: {
        route: 'usergroups',
        route_param: 'usergroup_id',
        api: 'user-groups',
        name: 'User Group',
        plural: 'User Groups',
      }
    }
  }
}
</script>