<template>
    <sc-modal :title="`${capitalizedType} Operational Message Details`" @close="$emit('close')">
        <div class="details-row">
            <div class="details-key">Title</div>
            <div class="details-value">{{ item.title }}</div>
        </div>
        <div class="details-row">
            <div class="details-key">Text</div>
            <div class="details-value">{{ item.text_1 }}</div>
        </div>

        <div class="details-row" v-if="creatorReady">
            <div class="details-key">Created by</div>
            <div class="details-value" v-if="creator">{{ creatorLine }}</div>
            <div class="details-value" v-else><i>Deleted User</i></div>
        </div>
        <div class="details-row">
            <div class="details-key">Created at</div>
            <div class="details-value">{{ item.created_at | toDatetime }}</div>
        </div>
        <div class="details-row">
            <div class="details-key">Updated at</div>
            <div class="details-value">{{ item.updated_at | toDatetime }}</div>
        </div>
        <div class="details-row">
            <div class="details-key">Players</div>
            <div class="details-value players">
                <ul>
                    <li v-for="p in item.players" :key="`player-${p.id}`">{{ p.name }}</li>
                </ul>
            </div>
        </div>
        <div class="details-row">
            <div class="details-key">Players Groups</div>
            <div class="details-value">
                <table>
                    <thead>
                    <tr>
                        <th>Group</th>
                        <th>Players</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="group in item.player_groups" :key="`group-${group.id}`">
                        <td>
                            {{ group.name }}
                        </td>
                        <td>
                            <ul>
                                <li v-for="p in group.players" :key="`group-player-${p.id}`">
                                    {{ p.name }}
                                </li>
                            </ul>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </sc-modal>
</template>

<script>
export default {
  name: "message-details",
  props: {
    type: {required: true, type: String},
    item: {required: true, type: Object}
  },

  data() {
    return {
      creatorReady: false,
      creator: undefined
    }
  },

  computed: {
    creatorLine() {
      return `${this.creator.first_name} ${this.creator.last_name} (${this.creator.email})`
    },
    capitalizedType() {
      return this.type.slice(0,1).toUpperCase() + this.type.slice(1,this.type.length);
    }
  },

  mounted() {
    this.$talker.api(`users/${this.item.created_by}`)
      .then((res) => {
        if (res.data.data.attributes) {
          this.creator = res.data.data.attributes
        }
        this.creatorReady = true;
      })


  },
}
</script>

<style scoped lang="scss">

.details-row {

    padding: .5em .75em;
    border: 1px solid #e2e2e2;

    &:nth-child(1n+2) {
        border-top: none;
    }

    div {
        display: inline-block;
    }

    i {
        font-style: italic;
    }

    .details-key {
        width: 140px;
    }

    .details-value {
        vertical-align: top;
        box-sizing: border-box;

        &.players {
            font-size: .8em;
        }

        ul {
            box-sizing: border-box;

            li {
                list-style: circle;
                margin-left: 1.5em;
                box-sizing: border-box;
            }
        }

        table {
            box-sizing: border-box;
            font-size: .8em;


            th, td {
                font-weight: bold;
                overflow: hidden;
                padding: .25em .5em;
                border: 1px solid #e2e2e2;
                box-sizing: border-box;
            }

            td {
                font-weight: normal;
                min-width: 300px;
            }
        }
    }
}

</style>