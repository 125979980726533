<template>
    <div class="pd main">
        <div class="panel mhg report-options">
            <div class="header">Reports</div>
            <div class="content ">
                <div class="report-option" @click="view('user-activities')" v-if="$user.isAtLeast(1)">
                    User Activities Report
                </div>
                <div class="report-option" @click="view('dormant-accounts')" v-if="$user.isAtLeast(1)">
                    Dormant Accounts Report
                </div>
                <div class="report-option" @click="view('expired-accounts')" v-if="$user.isAtLeast(1)">
                    Expired Accounts Report
                </div>
                <div class="report-option" @click="view('players-health')">
                    Players Health Report
                </div>
                <div class="report-option" @click="view('media-not-in-use')">
                    Media Not In Use Report
                </div>
                <div class="report-option" @click="view('media-usage')">
                    Media Usage Report
                </div>
                <div class="report-option" @click="view('incident-messages')">
                    Incident Messages Report
                </div>
            </div>
        </div>

        <div class="reports">
            <component v-bind:is="reportComponent" v-if="reportComponent"/>
            <div class="panel" v-else style="margin-left: 2em;">
                <div class="header">
                    Please choose a report on the left
                </div>
                <div class="pd pdh" style="text-align: center;">
                    Please select the report you wish to create from the left hand section
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import UserActivities from "@/views/app/reports/components/user-activities";
import DormantAccounts from "@/views/app/reports/components/dormant-accounts";
import PlayersHealth from "@/views/app/reports/components/players-health";
import MediaNotInUse from "@/views/app/reports/components/media-not-in-use";
import MediaInUse from "@/views/app/reports/components/media-in-use";
import IncidentMessages from "@/views/app/reports/components/incident-messages";
import ExpiredAccounts from "@/views/app/reports/components/expired-accounts";


export default {
  name: 'index',
  components: {
    ExpiredAccounts,
    IncidentMessages, MediaNotInUse, MediaInUse, PlayersHealth, DormantAccounts, UserActivities},
  data() {
    return {
      type: undefined,
    }
  },

  computed: {
    reportComponent() {
      if (this.type) {
        switch (this.type) {
          case "user-activities":
            if (this.$user.isAtLeast(1)) {
              return UserActivities;
            }
            break;
          case "dormant-accounts":
            if (this.$user.isAtLeast(1)) {
              return DormantAccounts;
            }
            break;
          case "expired-accounts":
            if (this.$user.isAtLeast(1)) {
              return ExpiredAccounts;
            }
            break;
          case "players-health":
            return PlayersHealth;
          case "media-not-in-use":
            return MediaNotInUse;
          case "media-usage":
            return MediaInUse;
          case "incident-messages":
            return IncidentMessages;
        }
      }

      return undefined
    }
  },

  mounted() {
    this.$nextTick(() => {
      if (this.$route.query) {
        if (this.$route.query.type) {
          this.view(this.$route.query.type);
        }
      }
    })
  },

  methods: {
    view(type) {
      this.type = type;
      this.$router.replace({query: {type: type}});
    }
  }
}
</script>

<style scoped lang="scss">

.main {
    display: flex;
}

.report-options {
    width: 240px;
    height: fit-content;

    .content {
        border: 1px solid #e2e2e2;
        padding: 0;
        border-bottom-left-radius: inherit;
        border-bottom-right-radius: inherit;
    }

    .report-option {
        padding: .75em 1em;
        text-align: center;
        cursor: pointer;
        border-bottom: 1px solid #e2e2e2;

        &:last-child {
            border-bottom: 0;
        }

        &:hover {
            background: #f6f6f6;
        }
    }
}

.reports {
    flex: 1;
    vertical-align: top;
    padding: 0;
    max-width: calc(100% - 240px);

    .table-container-with-inspect {
        padding-top: 0 !important;
        padding-right: 0 !important;
    }
}

</style>