import Vue from 'vue'
import store from './store/index.js'

Vue.prototype.$user = {
  isAtLeast(hierarchy) {
    return store.getters['user/isAtLeast'](hierarchy);
  },

  belongsToGroup(userGroupID) {
    return store.getters['user/belongsToGroup'](userGroupID);
  }
};