<template>
    <portal to="sc-modal">
        <div class="modal" @click="$emit('close')">
            <div class="modal-panel">
                <div class="modal-panel-inner" @click.stop>
                    <div class="modal-header">
                        <div class="modal-title pdt">
                            {{ title }}
                        </div>
                        <div class="close-button" @click="$emit('close')">
                            <awesome-icon class="close-button-icon" icon="times"/>
                        </div>
                    </div>

                    <div class="modal-content pdt">
                        <slot></slot>
                    </div>
                    <slot name="modal-footer"></slot>
                </div>
            </div>
            <div class="backdrop"></div>
        </div>
    </portal>
</template>

<script>
export default {
  name: "sc-modal",
  props: {
    title: {
      default: 'Confirm'
    }
  },
  emits: ['close']
}
</script>

<style scoped lang="scss">
.modal {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    /*display: flex;*/
    z-index: 100;
    padding: 6em 1em 2em;
    overflow: auto;
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
}

.modal-panel {
    margin: auto;
    text-align: center;
    position: relative;
    z-index: 1;

    .modal-panel-inner {
        background: #fff;
        border-radius: 0.4em;
        margin: auto;
        display: inline-block;
        text-align: left;
        min-width: 500px;
        max-width: 1200px;
        box-shadow: 1px 1px 20px 10px rgba(0, 0, 0, 0.1);
        cursor: default;

        .modal-header {
            border-radius: 0.3em 0.3em 0 0;
            display: flex;
            background: $color-primary;
            align-items: center;
            color: #fff;

            .modal-title {
                flex: 1;
                font-weight: 600;
            }

            .close-button {
                padding: 1.4em 1.5em;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                font-size: 0.9em;

                &:hover {
                    cursor: pointer;
                    background: rgba(0, 0, 0, 0.1);
                }

                .close-button-text {
                    font-weight: 600;
                    margin-right: 0.3em;
                }
            }
        }

        .modal-content {

        }
    }
}


.backdrop {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
}


</style>