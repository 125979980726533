<template>
    <div class="field-errors" v-if="errors[field]">
        <div class="error" v-for="e in errors[field]">
            {{e}}
        </div>
    </div>
</template>

<script>
export default {
  name: "sc-field-errors",
  props: {
    errors: {required: true},
    field: {required: true, type: String}
  },
}
</script>

<style scoped lang="scss">

.field-errors {
    color: var(--)
}
    .error {
        color: red;
        font-weight: 700;
        font-size: 0.8em;
        margin: 0.5em 0;
    }

</style>