<template>
    <div class="notification-panel" :class="{active:notifications.length > 0}">
        <transition-group name="notifications" tag="div" class="notifications" @enter="enter">
            <notification-panel-item
                    v-for="(notification, index) in notifications"
                    :notification="notification"
                    :key="notification.id"
                    @done="done(index)"
            >
            </notification-panel-item>
        </transition-group>
    </div>
</template>

<script>
import NotificationPanelItem from "./notification-panel-item.vue";

export default {
  components: {NotificationPanelItem},
  name: 'notification-panel',

  computed: {
    notifications() {
      return this.$store.state.notify.notifications;
    }
  },

  methods: {
    done(index) {
      this.$store.commit('notify/REMOVE_NOTIFICATION', index)
    },
    enter(el, done) {
      el.style.marginTop = `-${el.offsetHeight}px`
      done();
    }
  }
}
</script>

<style scoped lang="scss">
.notification-panel {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 200;
    pointer-events: none;
}

.notifications {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin: 0 1em 1em;
}

.notifications-enter-to {
    animation: slideIn 0.4s $easing forwards;
}

@keyframes slideIn {
    to {
        margin-top: 0;
    }
}

.notifications-leave-active {
    transition: all 0.3s ease-in-out;
}

.notifications-leave-to {
    opacity: 0;
}
</style>