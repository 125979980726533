<template>
    <sc-table :table="table">
        <template #header>
            <div class="table-header">
                Incident Messages
            </div>
            <div class="sub">
                <div class="filters">

                    <div class="filter">
                        <label>Player / Player Group</label>
                        <br/>
                        <select @change="setItem">
                            <option value="null">-- Select a player or a player group</option>
                            <optgroup label="Player Groups">
                                <option v-for="g in playerGroups" :key="`group-${g.id}`" :value="g.id+'-player_group'">
                                    {{ g.attributes.name }}
                                </option>
                            </optgroup>
                            <optgroup label="Players">
                                <option v-for="p in players" :key="`player-${p.id}`" :value="p.id+'-player'">
                                    {{ p.attributes.name }}
                                </option>
                            </optgroup>
                        </select>
                    </div>
                    <div class="filter">
                        <label>Start</label>
                        <br/>
                        <datepicker v-model="start_date" type="date"
                                    class="picker"/>
                    </div>

                    <div class="filter">
                        <label>End</label>
                        <br/>
                        <datepicker type="date" v-model="end_date"
                                    class="picker"/>
                    </div>
                </div>

                <div class="actions">
                    <sc-button @click="report('generate')" class="action"
                               :disabled="loading" :running="loading">
                        <awesome-icon icon="file-alt" class="icon"/>
                        Generate Report
                    </sc-button>
                    <sc-button class="action" @click="report('download')"
                               :disabled="loading" :running="loading">
                        <awesome-icon icon="download" class="icon"/>
                        Download Report
                    </sc-button>
                </div>
            </div>
        </template>

        <template #column-email="{row}">
            <sc-table-cell :table="table" field="email" style="word-break:break-word;">
                {{row.email}}
            </sc-table-cell>
        </template>

    </sc-table>
</template>

<script>
import datepicker from "vue2-datepicker";
import moment from "moment-timezone";
import 'vue2-datepicker/index.css';
import exporter from "@/views/app/reports/functions/exporter";

export default {
  name: "dormant-accounts",
  components: {datepicker},
  data() {
    return {
      loading: false,

      playerGroups: [],
      players: [],

      item: undefined,
      item_type: undefined,

      start_date: moment().toDate(),
      end_date: undefined,

      table: this.$stable.createTable({
        name: "Incident Messages",
        url: 'reports/incident-messages',
        sortDir: 'desc',
        fetchArguments: () => {
          return this.getArguments();
        },
        columns: {
          id: {
            width: 80,
            sort: false
          },
          email: {sort: false, name: 'Created by'},
          type: {sort: false, width: 128},
          title: {sort: false},
          text_1: {
            name: 'body',
            sort: false,
          },
          player: {sort: false},
          player_groups: {sort: false},
          start_date: {type: 'date', name: 'Start time', sort: false},
          end_date: {type: 'date', name: 'End time', sort: false},
          created_at: {type: 'date', sort: false},
          updated_at: {type: 'date', sort: false}
        },
        success: () => {
          this.loading = false;
        }
      })
    }
  },

  mounted() {
    this.$talker.api('players?page=1&amount=10000')
      .then((res) => {
        this.players = res.data.data;
      })
    this.$talker.api('player-groups')
      .then((res) => {
        this.playerGroups = res.data.data;
      })
  },

  methods: {
    getArguments() {
      let start = moment(this.start_date).format("YYYY-MM-DD")
      let args = `&start_date=${start}&${this.item_type}=${this.item}`
      if (this.end_date) {
        let end = moment(this.end_date).format("YYYY-MM-DD");
        args += `&end_date=${end}`
      }
      return args
    },

    report(type) {
      this.loading = true;
      if (this.item && this.start_date) {
        if (type === 'generate') {
          this.table.fetch()
          .catch((err) => {
            this.loading = false;
          })
        } else if (type === 'download') {
          this.downloadReport()
        }
      } else {
        this.loading = false;
        this.$notify.error("The Player / PlayerGroup and Start Date fields are required.");
      }
    },

    downloadReport() {
      let query = 'reports/export/incident-messages?';
      query += this.getArguments().slice(1, this.getArguments().length);
      this.$talker.api(query, {
        responseType: 'blob'
      })
        .then((res) => {
          exporter(res.data, "incidentmessages");
          this.loading = false;
        })
        .catch((err) => {
          this.$notify.error("Something went wrong");
          this.loading = false;
        })
    },

    setItem(e) {
      let val = e.target.value;
      this.item = parseInt(val.split('-')[0]);
      this.item_type = val.split('-')[1];
    }
  }
}
</script>

<style scoped lang="scss">

.sub {
    display: flex;
    padding: 1em 1.5em;

    .filters {
        display: flex;
        align-items: center;
        flex: 1;

        .filter {
            display: inline-block;
            text-align: center;
            width: calc(100% / 3);
            padding: 1em;
            box-sizing: border-box;
            cursor: pointer;

            label {
                font-weight: bold;
            }

            input, select {
                margin-top: 1em;
            }

            .picker {
                margin-top: 1em;
            }
        }
    }

    .actions {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;

        .action {
            margin: .25em 0;
        }
    }
}

.filters {
    width: 100%;
}

.not-available {
    color: $color-primary;
}

.values {
    word-break: break-word;
}

</style>