<template>
    <sc-table :table="table">
        <template #header>
            <div class="table-header">
                User Activities
            </div>
            <div class="sub">
                <div class="filters">

                    <div class="filter">
                        <label>User</label>
                        <input type="text" @focus="displayUsers = true" v-if="!user"/>
                        <input v-else type="text" :value="user.attributes.email" @click="displayUsers=true"/>
                        <select-user @close="displayUsers = false" v-if="displayUsers" @select="selectUser"
                                     @clear="clearUser"/>
                    </div>
                    <div class="filter">
                        <label>Start</label>
                        <br/>
                        <datepicker v-model="start_date" type="date"
                                    class="picker"/>
                    </div>

                    <div class="filter">
                        <label>End</label>
                        <br/>
                        <datepicker type="date" v-model="end_date"
                                    class="picker"/>
                    </div>

                </div>
                <div class="actions">
                    <sc-button @click="report('generate')" class="action"
                               :disabled="loading" :running="loading">
                        <awesome-icon icon="file-alt" class="icon"/>
                        Generate Report
                    </sc-button>
                    <sc-button class="action" @click="report('download')"
                               :disabled="loading" :running="loading">
                        <awesome-icon icon="download" class="icon"/>
                        Download Report
                    </sc-button>
                </div>
            </div>

        </template>

        <template #column-old_value="{row}">
            <sc-table-cell :table="table" field="old_value" class="values">
                <template v-if="row.old_value">
                        <span class="line"
                              v-if="Object.keys(row.old_value).length > 0"
                              v-for="key in Object.keys(row.old_value)">
                                <b>{{ key }}:</b>&nbsp; {{ row.old_value[key] }}
                        </span>
                </template>
                <span v-else class="not-available"><b>n/a</b></span>
            </sc-table-cell>
        </template>

        <template #column-new_value="{row}">
            <sc-table-cell :table="table" field="new_value" class="values">
                <template v-if="row.new_value">
                        <span class="line"
                              v-if="Object.keys(row.new_value).length > 0"
                              v-for="key in Object.keys(row.new_value)">
                            <b>{{ key }}:</b>&nbsp; {{ row.new_value[key] }}
                        </span>
                </template>
                <span v-else class="not-available"><b>n/a</b></span>
            </sc-table-cell>
        </template>
    </sc-table>
</template>

<script>
import SelectUser from "@/views/app/admin/activity-logs/select-user";
import datepicker from "vue2-datepicker";
import moment from "moment-timezone";
import 'vue2-datepicker/index.css';
import exporter from "@/views/app/reports/functions/exporter";

export default {
  name: "user-activities",
  components: {SelectUser, datepicker},
  data() {
    return {
      loading: false,

      // Filters
      start_date: moment().toDate(),
      end_date: undefined,
      user: undefined,
      displayUsers: false,

      table: this.$stable.createTable({
        name: "User Activity Reports",
        url: 'reports/user-activities',
        amount: 15,
        fetchArguments: () => {
          return this.getArguments();
        },
        columns: {
          date: {
            target: 'created_at',
            sort: false,
            type: 'date',
            width: 128
          },
          action: {sort: false, width: 128},
          type: {sort: false, width: 128},
          old_value: {sort: false, fill: true},
          new_value: {sort: false, fill: true}
        },
        success: () => {
          this.loading = false;
        }
      })
    }
  },

  methods: {
    changePage(target) {
      this.table.changePage(target);
    },

    getArguments() {
      let start = moment(this.start_date).format("YYYY-MM-DD")
      let args = `&user=${this.user.id}&start_date=${start}`
      if (this.end_date) {
        let end = moment(this.end_date).format("YYYY-MM-DD");
        args += `&end_date=${end}`
      }
      return args;
    },

    report(type) {
      this.loading = true;
      if (this.user && this.start_date) {
        if (type === 'generate') {
          this.table.fetch()
          .catch((err) => {
            this.loading = false;
          })
        } else if (type === 'download') {
          this.downloadReport()
        }
      } else {
        this.loading = false;
        this.$notify.error("The User and Start Date fields are required.");
      }
    },

    downloadReport() {
      let query = 'reports/export/user-activities?';
      query += this.getArguments().slice(1, this.getArguments().length);
      this.$talker.api(query, {
        responseType: 'blob'
      })
        .then((res) => {
          exporter(res.data, "useractivities");
          this.loading = false;
        })
        .catch((err) => {
          this.$notify.error("Something went wrong");
          this.loading = false;
        })
    },

    selectUser(u) {
      this.user = u;
    },

    clearUser() {
      this.user = undefined;
    },
  }
}
</script>

<style scoped lang="scss">

.sub {
    display: flex;
    padding: 1em 1.5em;

    .filters {
        display: flex;
        flex: 1;

        .filter {
            display: inline-block;
            text-align: center;
            width: calc(100% / 3);
            padding: 1em;
            box-sizing: border-box;

            label {
                font-weight: bold;
            }

            input {
                margin-top: 1em;
            }

            .picker {
                margin-top: 1em;
            }
        }
    }

    .actions {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;

        .action {
            margin: .25em 0;
        }
    }
}


.not-available {
    color: $color-primary;
}

.values {
    word-break: break-word;
}

</style>