<template>
    <div>
        <children-route :passed="resource"></children-route>
    </div>
</template>

<script>
export default {
  name: 'index',

  data() {
    return {
      resource: {
        route: 'users',
        route_param: 'user_id',
        api: 'users',
        name: 'User',
        plural: 'Users',
      }
    }
  }
}
</script>