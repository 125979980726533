<template>
    <sc-table :table="table" v-if="table" class="m-table" :key="'table-'+type">
        <template #headers>
            <div style="display:none" v-if="type !== 'expired'"/>
            <div class="expire-filters" v-else>
                <div class="filter">
                    <label> Expired in the last: </label>
                    <select v-model="expiredRange" @change="fetch">
                        <option selected :value="24">24 hours</option>
                        <option :value="week">week</option>
                        <option :value="month">30 days</option>
                    </select>
                </div>
            </div>
        </template>

        <template #column-priority="{row}">
            <sc-table-cell :table="table" field="priority">
                <img :src="imageURL(row.priority.media_src)" class="m-priority"/>
            </sc-table-cell>
        </template>

        <template #column-content="{row}">
            <sc-table-cell :table="table" field="content" class="m-content">
                <div class="title">
                    <template v-if="row.title">
                        <h3>{{ row.title | limitTo(100) }}</h3>
                        <span v-if="row.title.length > 100">...</span>
                    </template>
                </div>
                <div class="text">
                    <template v-if="row.text_1">
                        {{ row.text_1 | limitTo(150) }}
                        <span v-if="row.text_1.length > 150">...</span>
                    </template>
                </div>

                <div class="expire">
                    <div class="line">
                        <b>{{ expirationIndicator }} {{ type === 'scheduled' ? row.start_date : row.end_date | toDateHuman }}</b>
                        <span> ({{ row.start_date | toDatetime }}
                        <b>to</b>
                        {{ row.end_date | toDatetime }})
                    </span>
                    </div>
                    <div class="warning" v-if="type === 'live' && outdatedWarning(row)"
                         :class="{expiry: expiryWarning(row)}">
                        <b>
                            <awesome-icon icon="exclamation-triangle"/>
                            Expiry Warning
                        </b>
                    </div>
                </div>
            </sc-table-cell>
        </template>

        <template #column-preview="{row}">
            <sc-table-cell :table="table" field="preview">
                <img :src="imageURL(row.bg_image)" class="m-preview" v-if="row.bg_image"
                     data-tooltip="Image only message"/>
            </sc-table-cell>
        </template>

        <template #column-details="{row}">
            <sc-table-cell :table="table" field="details" class="m-option">
                <sc-table-button icon="eye" tooltip="Details" @click="detailsItem = row"/>
            </sc-table-cell>
            <message-details v-if="detailsItem" :item="detailsItem" @close="detailsItem = undefined;" :type="type"/>
        </template>

        <template #column-expire="{row}">
            <sc-table-cell :table="table" field="expire" class="m-option">
                <sc-table-button @click="expireMessage(row)" :icon="['far','calendar-times']" tooltip="expire"
                                 v-if="type!=='expired'">
                </sc-table-button>
            </sc-table-cell>
        </template>

        <template #column-edit="{row}">
            <sc-table-cell :table="table" field="edit" class="m-option">
                <sc-table-button icon="edit" @click="editMessage(row)" tooltip="edit"/>
            </sc-table-cell>
        </template>
    </sc-table>
</template>

<script>
import moment from "moment-timezone";
import MessageDetails from "@/views/app/home/components/message-details";

export default {
  name: "messages-container",
  components: {MessageDetails},
  props: {
    type: {required: true, type: String},
  },
  data() {
    return {
      ready: false,
      amount: 5,
      current_page: 1,
      table: null,
      detailsItem: undefined,
      interval: undefined,
      intervalDuration: 10,
      expiredRange: 24
    }
  },
  computed: {
    capitalizedType() {
      if (this.type) {
        return this.type.slice(0, 1).toUpperCase() + this.type.slice(1, this.type.length);
      }
    },

    day() {
      return 24;
    },
    week() {
      return this.day * 7
    },
    month() {
      return this.day * 30
    },

    expirationIndicator() {
      if (this.type === "scheduled") return "Due to start";
      if (this.type === "live") return "Due to expire"
      return "Expired"
    },

    timezone() {
      return moment.tz.guess();
    },
  },

  beforeDestroy() {
    if (this.interval) {
      window.clearInterval(this.interval);
      this.interval = undefined;
    }
  },

  mounted() {
    this.table = this.$stable.createTable({
      name: `${this.capitalizedType} Operational Messages`,
      url: 'dashboard',
      fetchArguments: () => {
        let args = `&filter=${this.type}`
        if (this.type === 'expired') {
          args += `&expired_time_travel=${this.expiredRange}`
        }
        return args
      },
      amount: 5,
      sortBy: this.type === 'scheduled' ? 'start_date': 'end_date',
      sortDir: this.type === 'expired' ? 'desc' : 'asc',
      columns: {
        priority: {
          sort: false,
          width: 150
        },
        content: {
          sort: false,
          fill: true
        },
        preview: {
          sort: false,
          fill: true
        },
        details: {sort: false, width: 60},
        expire: {sot: false, width: 60},
        edit: {sort: false, width: 60}
      }
    })

    this.fetch();
  },

  methods: {

    moment(d) {
      return moment(d).valueOf();
    },

    fetch() {
      this.table.fetch();
      if (this.interval) {
        window.clearInterval(this.interval);
        this.interval = undefined;
      }

      this.interval = window.setInterval(() => {
        this.table.fetch();
      },this.intervalDuration * 1000);
    },

    imageURL(path) {
      return process.env.VUE_APP_AWS_URL + path;
    },

    outdatedWarning(row) {
      // return if start_date is greater than 20 minutes ago
      let now = moment.tz(this.timezone);
      let start = moment.tz(row.start_date, this.timezone);
      return Math.abs(start.diff(now, 'minutes')) > 20;
    },

    expiryWarning(row) {
      // return if end_date is les than 20 minutes from now
      let now = moment.tz();
      let end = moment.tz(row.end_date, 'UTC');
      return Math.abs(end.diff(now, 'minutes')) < 20;
    },


    editMessage(row) {
      this.$router.push(`templates/${row.id}/edit?tab=6`)
    },

    expireMessage(row) {
      let title = `${row.title ? row.title : 'image only'}`
      if (confirm(`Do you want to expire message ${title}`)) {
        this.$talker.api(`templates/${row.id}/expire`)
          .then((res) => {
            this.$notify.success(`Message ${title} expired.`)
            this.fetch();
          })
      }
    }
  }
}
</script>

<style scoped lang="scss">

.empty {
    text-align: center;
    padding: 1em 0;
}

.m-priority {
    max-width: 100px;
}

.m-content {
    .expire {
        .warning {
            margin-top: .5em;
            background: $color-primary;
            border-radius: 5px;
            padding: .25em .5em;
            color: #FFF;
            max-width: 128px;

            &.expiry {
                background: $color-secondary;
            }
        }
    }
}

.m-preview {
    max-height: 150px;
    max-width: 100%;
}

.m-table {
    ::v-deep .row {
        overflow: initial !important;
    }

    &:last-child {
        border-bottom: none !important;
    }
}

.expire-filters {
    margin: 1em 0 2em;
}

</style>