<template>
    <sc-table-cell :table="table" :field="field">
        {{ row.attributes[field] | toDateHuman }}
        <template slot="sub">
            {{ row.attributes[field] | toDatetime }}
        </template>
    </sc-table-cell>

</template>

<script>
export default {
  name: "sc-table-date-field",
  props: {
    table: {required: true},
    field: {required: false},
    row: {required: true}
  }
}
</script>

<style scoped>

</style>