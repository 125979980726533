import index from './index.vue'
import playerGroups from './player-groups/routes'
import playerMetadata from './player-metadata/routes'

const routes = {
  path: 'players',
  component: index,
  children: [
    {
      path: 'index',
      component: () => import('./all.vue'),
    },
    {
      path: 'new',
      component: () => import('./new.vue'),
    },
    {
      path: ':player_id/edit',
      component: () => import('./edit.vue'),
    },
    playerGroups,
    playerMetadata
  ]
};

export default routes;
