import Vue from 'vue';
import VueRouter from 'vue-router';

import Talker from './plugins/talker'
import sForm from '@scala-services/scala-form'
import sTable from '@scala-services/scala-table'
import Notify from './plugins/notification-panel'
import store from "@/store";
import router from '@/router';
import PortalVue from 'portal-vue'
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {far} from '@fortawesome/free-regular-svg-icons'
import {fab} from '@fortawesome/free-brands-svg-icons'

import ComponentsLoader from './components/common'
import FiltersLoader from './filters'

Vue.use(VueRouter);
Vue.use(PortalVue);
Vue.use(ComponentsLoader);
Vue.use(FiltersLoader);
library.add(fas);
library.add(far);
library.add(fab);
Vue.component('awesome-icon', FontAwesomeIcon);

// Custom Axios wrapper Class
Vue.use(Talker, {
  baseURL: (process.env.NODE_ENV === 'development') ? process.env.VUE_APP_URL : '',
  interceptors: {
    401: '/#/login' // Unauthorized
  },
  cookie: process.env.VUE_APP_COOKIE,
  mode: process.env.VUE_APP_LOGIN_MODE
})

// Notification panel
Vue.use(Notify, {store});

// Form Class Wrapper. Requires Axios instance.
// Optional success & failure callbacks, which can be overwritten on each form instance
Vue.use(sForm, {
  axiosInstance: Vue.prototype.$talker.api,
  success: () => {
    Vue.prototype.$notify.success('Submit succeeded')
  },
  failure: error => {
    if (error.response && error.response.status === 422) {
      let message = 'Missing required fields';
      if (error.response.message || error.response.data) {
        if (error.response.message) {
          message = error.response.message;
        } else {
          if (error.response.data.message) {
            message = error.response.data.message;
          }
        }
      }
      Vue.prototype.$notify.error(message)
    } else if (error.response && error.response.status === 500 && error.response.data) {
      Vue.prototype.$notify.error(error.response.data)
    } else if (error.response && error.response.status === 403) {
      Vue.prototype.$notify.error('Access denied')
    } else {
      Vue.prototype.$notify.error('Failed to submit')
    }
  }
});

// Table Class Wrapper. Requires Axios instance.
// Optional success & failure callbacks, which can be overwritten on each table instance
Vue.use(sTable, {
  axiosInstance: Vue.prototype.$talker.api,
  success: () => {
    //
  },
  failure: error => {
    if (error.response) {
      if (error.response.status === 403) {
        router.replace('/');
        Vue.prototype.$notify.error('Not authorized');
      } else if (error.response.status === 401) {
        Vue.prototype.$notify.error("Session expired");
      } else {
        Vue.prototype.$notify.error('Something went wrong');
      }
    } else {
      Vue.prototype.$notify.error('Something went wrong');
    }
  }
});


