import index from './index.vue'
import badWords from './bad-words/routes'
import companies from './companies/routes'
import userGroups from './user-groups/routes'
import users from './users/routes'
import framesets from './framesets/routes'
import activityLogs from './activity-logs/routes'

const routes = {
  path: 'admin',
  component: index,
  children: [
    badWords,
    companies,
    userGroups,
    framesets,
    users,
    activityLogs
  ]
};

export default routes;
