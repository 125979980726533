<template>
    <div>
        <div class="btns">
            <sc-button @click="open">
                <slot></slot>
            </sc-button>
            <sc-button class="clear" @click="clear" v-if="selected">
                <awesome-icon icon="times"/>
            </sc-button>
        </div>
        <sc-modal v-if="active" @close="close" title="Choose Media Item">
            <sc-search class="search" :table="table" field="q"></sc-search>
            <div class="images">
                <div class="image" v-for="item in table.rows()" :key="item.id" @click="select(item)">
                    <img :src="imageURL(item)"/>
                </div>
            </div>
            <template #modal-footer>
                <sc-paginate :table="table"></sc-paginate>
            </template>


        </sc-modal>
    </div>
</template>

<script>
import ScSearch from "@/components/sc-search";

export default {
  name: "nr-image-picker",
  components: {ScSearch},
  props: {
    field: {
      required: true,
    }
  },

  emits: ['update:field'],

  data() {
    return {
      table: this.$stable.createTable({
        url: 'media',
        columns: {image: {}},
        fetchArguments: () => {
          return `&type=image`
        },
      }),
      selected: '',
      active: false,
    }
  },

  computed: {},

  mounted() {
    this.selected = this.field;
  },

  methods: {
    select(item) {
      this.selected = item.attributes.src
      this.close();
    },
    open() {
      this.active = true;
      this.table.fetch();
    },
    close() {
      this.$emit('update:field', this.selected)
      this.active = false;
    },
    clear() {
      this.selected = '';
      this.close();
    },
    imageURL(item) {
      return process.env.VUE_APP_AWS_URL + item.attributes.src
    },
  }
}
</script>

<style scoped lang="scss">
.images {
    display: grid;
    grid-template-columns: repeat( 5, 7em);
    grid-gap: 1em;

    .image {
        width: 7em;
        height: 7em;
        cursor: pointer;
        background: #eee;
        border: 1px solid #ccc;
        box-sizing: border-box;

        &:hover {
            box-shadow: 0 0 0 1px $color-primary;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.search {
    border: 1px solid #aaa;
    margin-bottom: 1em;
}

.btns {
    display: flex;
    align-items: center;
    justify-content: center;

    .clear {
        margin-left: 0.5em;
    }
}
</style>