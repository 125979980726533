import index from './index.vue'

const routes = {
  path: 'media',
  component: index,
  children: [
    {
      path: 'index',
      component: () => import('./all.vue'),
    },
    {
      path: 'new',
      component: () => import('./new.vue'),
    },
    {
      path: 'new/page',
      component: () => import('./new-page.vue')
    },
    {
      path: ':media_id/edit',
      component: () => import('./edit.vue'),
    },
  ]
};

export default routes;
