<template>
    <div>
        <children-route :passed="resource"></children-route>
    </div>
</template>

<script>
export default {
  name: 'index',

  data() {
    return {
      resource: {
        route: 'player-groups',
        route_param: 'player_group_id',
        api: 'player-groups',
        name: 'Player Group',
        plural: 'Player Groups',
      }
    }
  }
}
</script>