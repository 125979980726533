<template>
    <div class="search" :class="{dirty:search.length > 0}">
        <div class="search-icon search-btn">
            <awesome-icon icon="search"></awesome-icon>
        </div>
        <input v-model="search" :placeholder="placeholder" @input="changed"/>
        <div class="clear search-btn" @click="clear">
            <awesome-icon icon="times"></awesome-icon>
        </div>
    </div>
</template>

<script>
export default {
  name: "sc-search",

  props: {
    table: {},
    placeholder: {
      default: 'search ...',
      type: String
    },
    debounce: {
      default: 300,
      type: Number
    },
    field: {
      default: 'where_name',
      type: String
    }
  },

  data() {
    return {
      search: '',
      timeout: undefined,
      args: '',
    }
  },

  beforeUnmount() {
    if (this.table) {
      this.table.fetchArguments = () => this.args;
    }
    clearTimeout(this.timeout)
  },

  mounted() {
    if (this.table) {
      this.args = this.table.fetchArguments()
    }
  },

  methods: {
    changed() {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {

        if (this.table) {
          this.table.fetchArguments = () => {
            let args = (this.args) ? this.args : '';
            if (this.search.length > 0) args += `&${this.field}=${this.search}`;
            return args;
          }
          this.table.page = 1;
          this.table.fetch();
        }

        this.$emit('searched', this.search)

      }, this.debounce)
    },
    clear() {
      this.search = '';
      if (this.table) {
        this.table.fetchArguments = () => this.args;
        this.table.page = 1;
        this.table.fetch();
      }
      this.$emit('searched', this.search)
    }
  }
}
</script>

<style scoped lang="scss">
.search {
    display: flex;
    background: #fff;
    border-radius: 0.3em;
    position: relative;
    box-shadow: $shading;

    input {
        border: none;
        padding: 12px 3em;
        font-size: 0.9em;
    }

    .search-btn {
        width: 3em;
        min-height: 100%;
        font-size: 1em;
        display: flex;
        align-items: center;
        justify-content: center;

        &.search-icon {
            position: absolute;
            left: 0;
            pointer-events: none;
        }

        &.clear {
            position: absolute;
            right: 0;
            opacity: 0;
            pointer-events: none;

            &:hover {
                background: rgba(0, 0, 0, 0.1);
                cursor: pointer;
            }
        }

    }

    &.dirty .clear {
        opacity: 1;
        pointer-events: inherit;
    }
}
</style>