const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('./login.vue'),
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('./forgot-password.vue'),
  },
  {
    path: '/password-reset',
    name: 'password-reset',
    component: () => import('./password-reset.vue'),
  },
];

export default routes;
