<template>
    <div>
        <children-route :passed="resource"></children-route>
    </div>
</template>

<script>
export default {
  name: 'index',

  data() {
    return {
      resource: {
        route: 'framesets',
        route_param: 'frameset_id',
        api: 'framesets',
        name: 'Frameset',
        plural: 'Framesets',
      }
    }
  }
}
</script>