<template>
    <input type="password" v-model="form.fields[field]"/>
</template>

<script>
export default {
  name: "nr-password",
  props: {
    form: {required: true},
    field: {required: true}
  }
}
</script>

<style scoped>

</style>