export const module = {
  namespaced: true,
  state: () => ({
    notifications: [],
    current_id: 0,
  }),
  mutations: {
    ADD_NOTIFICATION(state, message) {
      state.current_id++;
      message.id = state.current_id
      state.notifications.unshift(message)
    },
    REMOVE_NOTIFICATION(state, index) {
      state.notifications.splice(index, 1)
    }
  },
  actions: {},
  getters: {}
}