import VueRouter from 'vue-router'

// import {createRouter, createWebHashHistory} from 'vue-router'

import app from '../views/app/app.vue';
import auth from '../views/auth/routes'

import home from '../views/app/home/routes'
import admin from '../views/app/admin/routes'

import templates from '../views/app/templates/routes'
import channels from '../views/app/channels/routes'
import playlists from '../views/app/playlists/routes'
import media from '../views/app/media/routes'
import account from '../views/app/your-account/routes';
import players from '../views/app/players/routes';
import ois from '../views/app/ois-templates/routes';
import help from '../views/app/help/routes';
import reports from '../views/app/reports/routes';

const routes = [
  {
    path: '/app',
    // redirect: 'dashboard',
    component: app,
    meta: {requiresAuth: false, navRoot: true},
    children: [
      home,
      admin,
      templates,
      channels,
      playlists,
      media,
      account,
      players,
      ois,
      help,
      reports
    ]
  },
  ...auth,
  {
    path: '/:pathMatch(.*)*',
    redirect: '/app/dashboard'
  }
]

// const router = createRouter({
//   scrollBehavior(to, from, savedPosition) {
//     return {x: 0, y: 0}
//   },
//   history: createWebHashHistory(),
//   routes
// })

const router = new VueRouter({
  routes
})

function getCookie() {
  let cookie = (process.env.VUE_APP_COOKIE) ? process.env.VUE_APP_COOKIE : 'bearer';

  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + cookie.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));

  return matches ? decodeURIComponent(matches[1]) : undefined;
}

router.beforeEach((to, from, next) => {

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!getCookie()) {
      let query = to.query;
      query['redirect'] = to.path;
      next({
        path: '/login',
        query: query,
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
