import index from './index.vue'

const routes = {
  path: 'channels',
  component: index,
  children: [
    {
      path: 'index',
      component: () => import('./all.vue'),
    },
    {
      path: 'new',
      component: () => import('./new.vue'),
    },
    {
      path: ':channel_id/edit',
      component: () => import('./edit.vue'),
    },
    {
      path: ':channel_id/schedule',
      component: () => import('./schedule.vue'),
    },
  ]
};

export default routes;
