<template>
    <select v-model="form.fields[field]" class="nr-select">
        <option v-if="displayEmpty" disabled value class="empty">{{ emptyText }}</option>
        <option v-for="o in selectOptions" :value="optionValue(o)" :selected="value === optionValue(o)">
            {{ optionText(o) }}
        </option>
    </select>
</template>

<script>

/**
 *
 * options:{
 *   // optional
 *   data: promise => returns array
 *   empty: boolean|string,
 *   resource: string,
 * }
 *
 */

export default {
  name: "nr-select",
  props: {
    form: {required: true, type: Object},
    field: {required: true, type: String},
    options: {required: true, type: Object},
  },

  data() {
    return {
      selectOptions: [],
      selected: undefined,
      empty: false,
      ready: false
    }
  },

  computed: {
    cleanLabel() {
      return this.field.replace(new RegExp('_', 'g'), ' ')
    },

    value() {
      return this.form.fields[this.field]
    },

    displayEmpty() {
      if (typeof this.empty === 'boolean') {
        return this.empty
      } else if (typeof this.empty !== 'undefined') {
        return true;
      }
    },
    emptyText() {
      if (typeof this.empty === 'boolean' && this.empty) {
        return `- select ${this.cleanLabel} -`;
      } else if (typeof this.empty !== 'undefined') {
        return `- ${this.empty} -`;
      }
    }
  },
  mounted() {
    // Look for data

    if (this.options.hasOwnProperty('resource') && this.options.hasOwnProperty('data')) {
      throw new Error("NR-SELECT can't have RESOURCE and DATA properties simultaneously");
    } else if (this.options.hasOwnProperty('data')) {
      this.options.data.then((options) => {
        this.selectOptions = options;
      })
    } else if (this.options.hasOwnProperty('resource')) {
      this.fetchResource(this.options.resource)
    } else {
      throw new Error("NR-SELECT expects DATA(:ARRAY) or RESOURCE(:STRING) in OPTIONS");
    }

    // Look for empty option
    if (this.options.hasOwnProperty('empty')) {
      this.empty = this.options.empty
    }

  },
  methods: {

    fetchResource(resource) {
      return this.$talker.api(resource)
        .then((res) => {
          if (res.data.data) {
            this.selectOptions = res.data.data;
          } else {
            this.selectOptions = res.data;
          }
        })
    },

    optionValue(option) {
      if (this.options.hasOwnProperty('targets')) {
        if (this.options.targets === 'none') {
          return option;
        } else if (this.options.targets.hasOwnProperty('value')) {
          return this.options.targets.value.split('.').reduce((o, i) => o[i], option);
        }
      }
      return option.id
    },
    optionText(option) {
      if (this.options.hasOwnProperty('targets')) {
        if (this.options.targets === 'none') {
          return option;
        } else if (this.options.targets.hasOwnProperty('text')) {
          return this.options.targets.text.split('.').reduce((o, i) => o[i], option);
        }
      }
      return option.attributes.name
    }
  }
}
</script>
