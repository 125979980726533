<template>
    <transition name="fade-in-transition" mode="out-in" tag="div" :class="{long}">
        <slot></slot>
    </transition>
</template>

<script>
  export default {
    name: 'fade-in-transition',
    props: {
      long: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style scoped lang="scss">
    .fade-in-transition-enter-active {
        transition-property: opacity;
        transition-duration: $transition-duration;
        transition-timing-function: $easing;
    }

    .fade-in-transition-enter {
        opacity: 0;
    }

    .fade-in-transition-enter-to {
        opacity: 1;
    }

    .fade-in-transition-leave-active {
        transition-property: opacity;
        transition-duration: $transition-duration;
        transition-timing-function: $easing;
    }

    .fade-in-transition-leave {
        opacity: 1;
    }

    .fade-in-transition-leave-to {
        opacity: 0;
    }
</style>