<template>
    <div>
        <children-route :passed="resource"></children-route>
    </div>
</template>

<script>
export default {
  name: 'index',

  data() {
    return {
      resource: {
        route: 'channels',
        route_param: 'channel_id',
        api: 'channels',
        name: 'Channel',
        plural: 'Channels',
      }
    }
  }
}
</script>