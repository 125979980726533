import axios from 'axios'

export default {
  install(app, params) {
    app.prototype.$talker = new Talker(params)
  }
};

class Talker {
  constructor({baseURL = 'https://www.example.com', interceptors, cookie = 'bearer', mode = 'token'}) {
    this.cookie = cookie;
    this.baseURL = baseURL;
    this.mode = mode;

    this.modes = ['token', 'session']
    if (!this.modes.includes(this.mode)) {
      throw new Error(`the mode "${this.mode}" is invalid. Only [${this.modes}] modes are allowed`)
    }

    this.api = axios.create({
      baseURL: baseURL + '/api',
    });

    // Set headers based on mode
    if (this.mode === 'token') {
      // Look for token in cookies and set if found.
      if (this.getCookie()) {
        this.api.defaults.headers.common['Authorization'] = 'Bearer ' + this.getCookie();
      }
    } else {
      this.api.defaults.withCredentials = true;
      this.api.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    }

    if (interceptors) {
      if (typeof interceptors !== 'object' || Array.isArray(interceptors)) {
        throw new Error("Expecting interceptors to be an Object. E.g. {401: '/login'}");

      } else {
        this.api.interceptors.response.use((response) => {
          // All is good, proceed
          return response

        }, (error) => {
          let route = interceptors[error.response.status];
          if (route) {
            window.location = route;
          }
          return Promise.reject(error);
        })
      }
    }
  }

  login({email, password}) {
    return Promise.resolve()
      .then(() => {
        return new Promise((resolve, reject) => {
          let credentials = {
            ...(email ? {email} : reject('no username')),
            ...(password ? {password} : reject('no password')),
          };
          return resolve(credentials)
        })
          .then(credentials => {
            return new Promise((resolve, reject) => {
              if (this.mode === 'token') {
                return resolve(this.loginToken(credentials));
              } else {
                return resolve(this.loginSession(credentials))
              }
            })
          })
      })
  }

  loginSession(credentials) {
    return Promise.resolve()
      .then(() => {
        return new Promise((resolve, reject) => {
          // Fetch CSRF-TOKEN
          this.api.get(this.baseURL + '/sanctum/csrf-cookie', {baseURL: ''})
            .then(() => {
              return resolve(credentials)
            })
            .catch((err) => {
              reject(err)
            })
        })
      })
      .then(credentials => {
        return new Promise((resolve, reject) => {
          this.api.post(this.baseURL + '/login', credentials, {baseURL: ''})
            .then((res) => {
              resolve()
            })
            .catch(error => {
              this.clearAuthorization();
              if (error.response && error.response.data) {
                reject(error.response.data);
              } else {
                reject({message: 'Failed to login'})
              }
            });
        });
      })
  }

  loginToken(credentials) {
    return new Promise((resolve, reject) => {
      this.api.post('auth/login', credentials)
        .then((res) => {
          if (res.data.meta && res.data.meta.access_token) {
            // Set header
            this.api.defaults.headers.common['Authorization'] = 'Bearer ' + res.data.meta.access_token;
            // Store as cookie
            this.setCookie(res.data.meta.access_token);
            return resolve()

          } else {
            return reject('No access token received')
          }

        })
        .catch(error => {
          this.clearAuthorization();
          if (error.response && error.response.data) {
            reject(error.response.data);
          } else {
            reject({message: 'Failed to login'})
          }
        });
    });
  }


  logout({router}) {
    return Promise.resolve()
      .then(() => {
        // Switch logout based on mode
        let route = (this.mode === 'session') ? this.baseURL + '/logout' : 'auth/logout';
        let conf = (this.mode === 'session') ? {baseURL: ''} : {};

        this.api.post(route, {}, conf)
          .then(() => {
            this.clearAuthorization();
          })
          .then(() => {
            if (router && router.currentRoute.name !== 'login') {
              router.push('/login')
            }
          })
      })
  }

  reAuthenticate({username, password}) {
    return Promise.resolve()
      .then(() => {
        this.clearAuthorization();
      })
      .then(() => {
        return this.login({username, password})
      })
      .catch(err => {
        this.cError('failed to re-authenticate:', err);
        throw(err);
      })
  }

  clearAuthorization() {
    this.api.defaults.headers.common['Authorization'] = null;
    this.clearCookie();
  }

  cError(...message) {
    let title = '%cTalker error';
    let design = "background:#aa0000;color:white;display:block;padding:0.5em 1em;";
    console.error(title, design, ...message);
  }

  clearCookie() {
    document.cookie = `${this.cookie}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }

  setCookie(token) {
    let cookie = `${this.cookie}=${token};path=/;`
    if (this.mode !== 'token') {
      cookie += 'secure';
    }
    document.cookie = cookie;
  }

  getCookie() {
    let matches = document.cookie.match(new RegExp(
      "(?:^|; )" + this.cookie.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));

    return matches ? decodeURIComponent(matches[1]) : undefined;
  }
}
