<template>
    <div>
        <notification-panel></notification-panel>
        <children-route :animate="true"></children-route>
    </div>
</template>

<script>
import ChildrenRoute from "@/components/common/children-route";

export default {
  name: 'root',
  components: {ChildrenRoute},
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,700,800,900&display=swap');
@import '@/design/_reset';
@import '@/design/_globals';
//@import '@/design/_variables.scss';
</style>
