import index from './index.vue'

const routes = {
  path: 'activity-logs',
  component: index,
  children: [
    {
      path: '',
      component: () => import('./all.vue'),
    },
    // {
    //   path: 'new',
    //   component: () => import('./new.vue'),
    // },
    // {
    //   path: ':badword_id/edit',
    //   component: () => import('./edit.vue'),
    // },
  ]
};

export default routes;
