<template>
    <div>
        <router-view class="page-container" :passed="passed" @passed="passedFromChild">
        </router-view>
    </div>

    <!--    <router-view class="page-container" v-if="animate"-->
    <!--                 :key="animateKey"-->
    <!--                 :passed="passed"-->
    <!--                 @passed="passedFromChild" v-slot="{Component}">-->

    <!--        <transition-->
    <!--                :enter-class="`page-transition-enter ${type} ${direction}`"-->
    <!--                :enter-active-class="`page-transition-enter-active ${type} ${direction}`"-->
    <!--                :enter-to-class="`page-transition-enter-to ${type} ${direction}`"-->

    <!--                :leave-class="`page-transition-leave ${type} ${direction}`"-->
    <!--                :leave-active-class="`page-transition-leave-active ${type} ${direction}`"-->
    <!--                :leave-to-class="`page-transition-leave-to ${type} ${direction}`">-->
    <!--            <component :is="Component" :passed="passed"></component>-->
    <!--        </transition>-->

    <!--    </router-view>-->


</template>

<script>
export default {
  name: "children-route",

  props: {
    animate: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'fade'
    },
    animateKey: {},
    passed: {}
  },

  data() {
    return {
      direction: 'left',
    }
  },

  methods: {
    passedFromChild(e) {
      this.$emit('passed', e);
    },
  }

}
</script>

<style scoped lang="scss">

.page-container {
    flex: 1;
}

.page-transition-enter-active,
.page-transition-leave-active {
    transition-property: opacity, transform;
    transition-duration: $transition-duration;
    transition-timing-function: ease-out;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.page-transition-enter-active {
    z-index: 10;
    position: relative;
}

.page-transition-enter {
    opacity: 0;
}

.page-transition-enter-to {
    opacity: 1;
}

.page-transition-leave-active {
    z-index: 9;
}

.page-transition-leave-to {
    opacity: 0;
}
</style>