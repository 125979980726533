<template>
    <div>
        <children-route></children-route>
    </div>
</template>

<script>
export default {
  name: 'index',

  data() {
    return {}
  }
}
</script>