import moment from "moment-timezone"

export default function exporter(data, name) {
    // Get stamp
  let stamp = moment().format('YYYY-MM-DD_HH%mm').replace('%', 'h');
  let blob = new Blob([data], {type: 'application/vnd.ms-excel'});
  let filename = `${name}-${stamp}.xlsx`;

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
