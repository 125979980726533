<template>
    <sc-table :table="table">
        <template #header>
            <div class="table-header">
                Players Health
            </div>
            <div class="sub">
                <div class="filters">
                    <div class="filter">
                        <div class="description">
                            Please select the player health
                        </div>
                        <label>
                            <input type="radio" v-model="status" value="online" @change="report('generate')">
                            Online
                        </label>
                        <label>
                            <input type="radio" v-model="status" value="offline" @change="report('generate')">
                            Offline
                        </label>
                        <label>
                            <input type="radio" v-model="status" value="all" :selected="status === 'all'"
                                   @change="report('generate')">
                            All
                        </label>
                    </div>
                </div>

                <div class="actions">
                    <sc-button class="action" @click="report('download')"
                    :disabled="loading" :running="loading">
                        <awesome-icon icon="download" class="icon"/>
                        Download Report
                    </sc-button>
                </div>
            </div>
        </template>
    </sc-table>
</template>

<script>
import exporter from "@/views/app/reports/functions/exporter";

export default {
  name: "players-health",
  data() {
    return {
      loading: false,
      status: "online",

      table: this.$stable.createTable({
        name: "Players Health",
        url: 'reports/players-health',
        sortDir: 'desc',
        fetchArguments: () => {
          return this.getArguments();
        },
        columns: {
          id: {
            width: 80,
            sort: false
          },
          asset_id: {sort: false, width: 128},
          name: {sort: false, fill: true},
          state: {sort: false},
          active: {sort: false},
          last_reported: {sort: false, type: 'date', name: 'Last Online'},
        },
        success: () => {
          this.loading = false;
        }
      })
    }
  },

  mounted() {
    this.report('generate');
  },

  methods: {
    getArguments() {
      return `&status=${this.status}`;
    },

    report(type) {
      this.loading = true;
      if (type === 'generate') {
        this.table.fetch()
        .catch((err) => {
          this.loading = false;
        })
      } else if (type === 'download') {
        this.downloadReport()
      }
    },

    downloadReport() {
      let query = 'reports/export/players-health?';
      query += this.getArguments().slice(1, this.getArguments().length);
      this.$talker.api(query, {
        responseType: 'blob'
      })
        .then((res) => {
          exporter(res.data, "playershealth");
          this.loading = false;
        })
        .catch((err) => {
          this.$notify.error("Something went wrong");
          this.loading = false;
        })
    }
  }
}
</script>

<style scoped lang="scss">

.sub {
    display: flex;
    padding: 1em 1.5em;

    .filters {
        display: flex;
        align-items: center;
        flex: 1;

        .description {
            margin-right: 1em;
        }

        .filter {
            display: flex;
            text-align: center;
            box-sizing: border-box;

            label {
                display: flex;
                font-weight: bold;
                cursor: pointer;
                margin: 0 .5em;

                input {
                    min-width: initial;
                    widtH: 2em;
                }
            }
        }
    }

    .actions {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;

        .action {
            margin: .25em 0;
        }
    }

}

</style>