var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.table)?_c('sc-table',{key:'table-'+_vm.type,staticClass:"m-table",attrs:{"table":_vm.table},scopedSlots:_vm._u([{key:"headers",fn:function(){return [(_vm.type !== 'expired')?_c('div',{staticStyle:{"display":"none"}}):_c('div',{staticClass:"expire-filters"},[_c('div',{staticClass:"filter"},[_c('label',[_vm._v(" Expired in the last: ")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.expiredRange),expression:"expiredRange"}],on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.expiredRange=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.fetch]}},[_c('option',{attrs:{"selected":""},domProps:{"value":24}},[_vm._v("24 hours")]),_c('option',{domProps:{"value":_vm.week}},[_vm._v("week")]),_c('option',{domProps:{"value":_vm.month}},[_vm._v("30 days")])])])])]},proxy:true},{key:"column-priority",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"table":_vm.table,"field":"priority"}},[_c('img',{staticClass:"m-priority",attrs:{"src":_vm.imageURL(row.priority.media_src)}})])]}},{key:"column-content",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{staticClass:"m-content",attrs:{"table":_vm.table,"field":"content"}},[_c('div',{staticClass:"title"},[(row.title)?[_c('h3',[_vm._v(_vm._s(_vm._f("limitTo")(row.title,100)))]),(row.title.length > 100)?_c('span',[_vm._v("...")]):_vm._e()]:_vm._e()],2),_c('div',{staticClass:"text"},[(row.text_1)?[_vm._v(" "+_vm._s(_vm._f("limitTo")(row.text_1,150))+" "),(row.text_1.length > 150)?_c('span',[_vm._v("...")]):_vm._e()]:_vm._e()],2),_c('div',{staticClass:"expire"},[_c('div',{staticClass:"line"},[_c('b',[_vm._v(_vm._s(_vm.expirationIndicator)+" "+_vm._s(_vm._f("toDateHuman")(_vm.type === 'scheduled' ? row.start_date : row.end_date)))]),_c('span',[_vm._v(" ("+_vm._s(_vm._f("toDatetime")(row.start_date))+" "),_c('b',[_vm._v("to")]),_vm._v(" "+_vm._s(_vm._f("toDatetime")(row.end_date))+") ")])]),(_vm.type === 'live' && _vm.outdatedWarning(row))?_c('div',{staticClass:"warning",class:{expiry: _vm.expiryWarning(row)}},[_c('b',[_c('awesome-icon',{attrs:{"icon":"exclamation-triangle"}}),_vm._v(" Expiry Warning ")],1)]):_vm._e()])])]}},{key:"column-preview",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{attrs:{"table":_vm.table,"field":"preview"}},[(row.bg_image)?_c('img',{staticClass:"m-preview",attrs:{"src":_vm.imageURL(row.bg_image),"data-tooltip":"Image only message"}}):_vm._e()])]}},{key:"column-details",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{staticClass:"m-option",attrs:{"table":_vm.table,"field":"details"}},[_c('sc-table-button',{attrs:{"icon":"eye","tooltip":"Details"},on:{"click":function($event){_vm.detailsItem = row}}})],1),(_vm.detailsItem)?_c('message-details',{attrs:{"item":_vm.detailsItem,"type":_vm.type},on:{"close":function($event){_vm.detailsItem = undefined;}}}):_vm._e()]}},{key:"column-expire",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{staticClass:"m-option",attrs:{"table":_vm.table,"field":"expire"}},[(_vm.type!=='expired')?_c('sc-table-button',{attrs:{"icon":['far','calendar-times'],"tooltip":"expire"},on:{"click":function($event){return _vm.expireMessage(row)}}}):_vm._e()],1)]}},{key:"column-edit",fn:function(ref){
var row = ref.row;
return [_c('sc-table-cell',{staticClass:"m-option",attrs:{"table":_vm.table,"field":"edit"}},[_c('sc-table-button',{attrs:{"icon":"edit","tooltip":"edit"},on:{"click":function($event){return _vm.editMessage(row)}}})],1)]}}],null,false,812291993)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }