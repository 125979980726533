import index from './index.vue'

const routes = {
  path: 'help',
  component: index,
  children: [
    {
      path: 'user-guide',
      component: () => import('./user-guide.vue'),
    },
    {
      path: 'user-videos',
      component: () => import('./user-videos.vue'),
    },
  ]
};

export default routes;
