import { render, staticRenderFns } from "./sc-table-date-field.vue?vue&type=template&id=2a8cf648&scoped=true&"
import script from "./sc-table-date-field.vue?vue&type=script&lang=js&"
export * from "./sc-table-date-field.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a8cf648",
  null
  
)

export default component.exports