import index from './index.vue'

const routes = {
  path: 'reports',
  component: index,
  children: [
    {
      path: 'user-activities',
      component: () => import('./components/user-activities'),
    },
    {
      path: 'dormant-accounts',
      component: () => import('./components/dormant-accounts')
    },
    {
      path: 'expired-accounts',
      component: () => import('./components/expired-accounts')
    },
    {
      path: 'players-health',
      component: () => import('./components/players-health')
    },
    {
      path: 'media-not-in-use',
      component: () => import('./components/media-not-in-use')
    },
    {
      path: 'media-in-use',
      component: () => import('./components/media-not-in-use')
    },
    {
      path: 'incident-messages',
      component: () => import('./components/incident-messages')
    }
  ]
};

export default routes;
