<template>
    <div class="main">
        <div style="text-align: right" class="pd">
            <router-link to="/app/templates/new?tab=0" class="button">Create new operational message</router-link>
        </div>
        <messages-container type="scheduled" />
        <messages-container type="live"/>
        <messages-container type="expired"/>
    </div>
</template>

<script>
import MessagesContainer from "@/views/app/home/components/messages-container";

export default {
  name: "home",
  components: {MessagesContainer},
}
</script>

<style scoped lang="scss">
.main {
    position: relative;
    flex: 1;
}

.expire-filters {
    padding: 1em;
    display: flex;
    font-weight: bold;
    border-bottom: 1px solid #e2e2e2;

    .filter {
        display: inline-flex;
        vertical-align: center;
        color: $color-primary;

        label {
            width: 260px;
        }

        label, select, input {
            display: inline-block;
            vertical-align: center;
        }

        select {
            margin-top: -1em;
        }
    }
}


</style>

