<template>
    <children-route/>
</template>

<script>
import ChildrenRoute from "@/components/common/children-route";

export default {
  name: "index",
  components: {ChildrenRoute},
}
</script>

<style scoped>
</style>