<template>
    <div>
        <children-route :passed="resource"></children-route>
    </div>
</template>

<script>
export default {
  name: 'index',

  data() {
    return {
      resource: {
        route: 'templates',
        route_param: 'template_id',
        api: 'templates',
        name: 'Operational Message',
        plural: 'Operational Messages',
      }
    }
  }
}
</script>