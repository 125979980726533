<template>
    <div class="nav">
        <div class="nav-group" v-for="item in items" v-if="item.showIf !== false">
            <router-link v-if="item.link" :to="item.link" @click.native="close()" class="nav-group-item">
                <div class="icon">
                    <awesome-icon :icon="item.icon"></awesome-icon>
                </div>
                <div class="name">{{ item.name }}</div>
            </router-link>
            <div v-else @click="openItem(item)" class="nav-group-item">
                <div class="icon">
                    <awesome-icon :icon="item.icon"></awesome-icon>
                </div>
                <div class="name">{{ item.name }}</div>
                <div class="arrow">
                    <awesome-icon v-if="item.open" icon="caret-down"/>
                    <awesome-icon v-else icon="caret-right"/>
                </div>
            </div>
            <div class="nav-children" v-if="item.children" :class="{open:item.open === true}">
                <div class="nav-child" v-for="child in item.children" v-if="child.showIf !== false">
                    <router-link :to="child.link" class="nav-child-link">
                        <div class="icon">
                            <awesome-icon icon="caret-right"></awesome-icon>
                        </div>
                        <div class="name">{{ child.name }}</div>
                    </router-link>
                </div>
            </div>
        </div>
        <div class="logout">
            <div class="logout-button" @click="logout">
                <div class="name">
                    Log Out
                </div>
                <div class="icon">
                    <awesome-icon icon="sign-out-alt"/>
                </div>
            </div>
        </div>
        <div class="info">
            For all Digital Signage queries, please contact the IT Helpdesk on 085 51800 (Internal) or 01270 721 800
            (External)
        </div>
    </div>
</template>

<script>
export default {
  name: "sc-nav",

  data() {
    return {
      items: [
        {
          name: 'Home',
          icon: 'home',
          link: '/app/dashboard'
        },
        {
          name: 'Players',
          icon: 'play-circle',
          children: [
            {name: 'Player Dashboard', link: '/app/players/index',},
            {name: 'Add Player', link: '/app/players/new', showIf: this.$user.isAtLeast(1)},
            {name: 'Player Groups', link: '/app/players/player-groups', showIf: this.$user.isAtLeast(3)},
            {name: 'Player Metadata', link: '/app/players/player-metadata', showIf: this.$user.isAtLeast(3)}
          ]
        },
        {
          name: 'Playlist',
          icon: 'list',
          children: [
            {name: 'Playlist Home', link: '/app/playlists/index'},
            {name: 'Create Playlist', link: '/app/playlists/new'},
          ],
          showIf: this.$user.isAtLeast(3)
        },
        {
          name: 'Media',
          icon: 'image',
          children: [
            {name: 'Media Home', link: '/app/media/index'},
            {name: 'Upload Media', link: '/app/media/new'},
            {name: 'Create web page', link: '/app/media/new/page'},
          ]
        },
        {
          name: 'Channels',
          icon: ['far', 'square'],
          children: [
            {name: 'Channel Home', link: '/app/channels/index'},
            {name: 'Create Channel', link: '/app/channels/new', showIf: this.$user.isAtLeast(2)},
          ],
          showIf: this.$user.isAtLeast(3)
        },
        {
          name: 'Operational Messages',
          icon: ['far', 'object-group'],
          children: [
            {name: 'My Operational Messages', link: '/app/templates/index'},
            {name: 'Operational Message Wizard', link: '/app/templates/new'},
            {name: 'Operational Message Templates', link: '/app/templates/favourites'},
          ]
        },
        {
          name: 'OIS Templates',
          icon: ['far', 'list-alt'],
          link: '/app/ois-messages/index',
          showIf: this.$user.isAtLeast(1)
        },
        {
          name: 'Your Account',
          icon: 'user',
          link: '/app/account'
        },
        {
          name: 'Reports',
          icon: ['far', 'file-alt'],
          link: '/app/reports',
          showIf: this.$user.isAtLeast(2)
        },
        {
          name: 'Admin Mode',
          icon: 'lock',
          children: [
            {name: 'Users', link: '/app/admin/users', showIf: this.$user.isAtLeast(1)},
            {name: 'Dictionary', link: '/app/admin/bad-words', showIf: this.$user.isAtLeast(1)},
            {name: 'Companies', link: '/app/admin/companies', showIf: this.$user.isAtLeast(1)},
            {name: 'User Groups', link: '/app/admin/usergroups', showIf: this.$user.isAtLeast(1)},
            {name: 'Framesets', link: '/app/admin/framesets'},
            {name: 'Activity Logs', link: '/app/admin/activity-logs', showIf: this.$user.isAtLeast(1)},
          ],
          showIf: this.$user.isAtLeast(2)
        },
        {
          name: 'Help',
          icon: 'question',
          children: [
            {name: 'User Guide', link: '/app/help/user-guide'},
            {name: 'User Videos', link: '/app/help/user-videos'},
          ]
        }
      ]
    }
  },

  mounted() {
    let i = this.$route.path.indexOf('/app/');
    if (i !== -1) {
      let path = this.$route.path;
      let group = this.items.find(x => {
        if (x.children) {
          return x.children.find(c => {
            return c.link === path
          })
        }
      })
      if (group) {
        this.$set(group, 'open', true)
      }
    }
  },

  methods: {
    openItem(item) {
      this.close();
      this.$set(item, 'open', true)
    },

    close() {
      this.items.forEach(x => this.$set(x, 'open', false))
    },

    logout() {
      this.$talker.logout({router: this.$router});
    }
  }
}
</script>

<style scoped lang="scss">
.nav {
    width: 250px;
    box-shadow: $shading;
    background: #fefefe;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 2;

    &::-webkit-scrollbar {
        width: 7px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3);
    }

    .nav-group {
        border-bottom: 1px solid #e5e5e5;

        .nav-group-item {
            display: flex;
            align-items: stretch;

            &:hover {
                background: #eee;
                cursor: pointer;
            }

            .icon {
                width: 3rem;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #317087;
                color: #fefefe;
                font-size: 0.9em;
                margin-right: 1rem;
            }

            .name {
                flex: 1;
                padding: 0.6em 0;
                box-sizing: border-box;
                font-weight: 700;
                font-size: 0.8em;
            }

            .arrow {
                width: 2em;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .nav-children {
            display: none;
            padding: 0.5em 0;
            font-weight: 700;
            font-size: 0.8em;

            &.open {
                display: inherit;
            }

            .nav-child-link {
                display: flex;
                padding: 0.6em 0;
                line-height: 1.3em;

                &:hover {
                    background: #eee;
                    cursor: pointer;
                }

                .icon {
                    flex-shrink: 0;
                    width: 4rem;
                    text-align: center;
                    font-size: 1.3em;
                }
            }
        }

        .router-link-active {
            color: $color-primary;
        }
    }

    .logout {
        flex: 1;
        min-height: 3.5em;

        .logout-button {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 1.2em 0;
            border-bottom: 1px solid #e5e5e5;
            font-weight: 600;
            font-size: 0.8em;
            cursor: pointer;

            .icon {
                margin-left: 0.6em;
            }
        }
    }

    .info {
        padding: 0.5em;
        font-size: 0.95em;
        font-weight: 500;
        //margin-top: 3em;
    }

}
</style>