<template>
    <sc-table :table="table">
        <template #header>
            <div class="table-header">
                Expired Accounts
            </div>
            <div class="sub">
                <div class="filters">
                    <div class="filter">
                        <label>Password expired since</label>
                        <br/>
                        <datepicker v-model="expired_since" type="date"
                                    class="picker"/>
                    </div>
                </div>

                <div class="actions">
                    <sc-button @click="report('generate')" class="action"
                               :disabled="loading" :running="loading">
                        <awesome-icon icon="file-alt" class="icon"/>
                        Generate Report
                    </sc-button>
                    <sc-button class="action" @click="report('download')"
                               :disabled="loading" :running="loading">
                        <awesome-icon icon="download" class="icon"/>
                        Download Report
                    </sc-button>
                </div>
            </div>
        </template>

        <template #column-name="{row}">
            <sc-table-cell :table="table" field="name">
                {{ row.first_name }} {{ row.last_name }}
            </sc-table-cell>
        </template>
    </sc-table>
</template>

<script>
import datepicker from "vue2-datepicker";
import moment from "moment-timezone";
import 'vue2-datepicker/index.css';
import exporter from "@/views/app/reports/functions/exporter";

export default {
  name: "expired-accounts",
  components: {datepicker},
  data() {
    return {
      loading: false,

      // Filters
      expired_since: moment().toDate(),

      table: this.$stable.createTable({
        name: "Dormant Accounts",
        url: 'reports/expired-accounts',
        sortDir: 'desc',
        amount: 15,
        fetchArguments: () => {
          return this.getArguments();
        },
        columns: {
          id: {
            width: 80,
            sort: false
          },
          name: {sort: false, fill: true},
          last_login: {sort: false, width: 128, type: 'date'},
          password_expiry_time: {sort: false, type: 'date'},
        },
        success: () => {
          this.loading = false;
        }
      })
    }
  },

  methods: {
    getArguments() {
      let expired_since = moment(this.expired_since).format("YYYY-MM-DD")
      return `&expired_since=${expired_since}`;
    },

    report(type) {
      this.loading = true;
      if (type === 'generate') {
        this.table.fetch()
          .catch((err) => {
            this.loading = false;
          })
      } else if (type === 'download') {
        this.downloadReport()
      }
    },

    downloadReport() {
      let query = 'reports/export/expired-accounts?';
      query += this.getArguments().slice(1, this.getArguments().length);
      this.$talker.api(query, {
        responseType: 'blob'
      })
        .then((res) => {
          exporter(res.data, "expiredaccounts");
          this.loading = false;
        })
        .catch((err) => {
          this.$notify.error("Something went wrong");
          this.loading = false;
        })
    },
  }
}
</script>

<style scoped lang="scss">

.sub {
    display: flex;
    padding: 1em 1.5em;

    .filters {
        display: flex;
        align-items: center;
        flex: 1;

        .filter {
            display: inline-block;
            text-align: center;
            width: calc(100% / 3);
            padding: 1em;
            box-sizing: border-box;
            cursor: pointer;

            label {
                font-weight: bold;
            }

            input {
                margin-top: 1em;
            }

            .picker {
                margin-top: 1em;
            }
        }
    }

    .actions {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;

        .action {
            margin: .25em 0;
        }
    }
}

.filters {
    width: 100%;


}

.not-available {
    color: $color-primary;
}

.values {
    word-break: break-word;
}

</style>