import index from './index.vue'
import dashboard from './dashboard.vue'

const routes = {
  path: '',
  component: index,
  children: [
    {
      path: 'dashboard',
      component: dashboard,
    },
  ]
};

export default routes;
